import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/dev-mobile.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar2 from '../../assets/img/services/thumbnail/graphicdesign.jpg';
import similar3 from '../../assets/img/services/thumbnail/contenu.jpg';
import similar4 from '../../assets/img/services/thumbnail/conseils.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/mobile.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Application mobile | Agence digitale | MDS Digital Agency"
      description=" Parce que l’utilisation des smartphones ne cesse de croître, proposer une application mobile devient indispensable pour élargir son audience."
      keywords="application mobile"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Création d’applications mobiles</h2>
      <p>
        Parce que l’utilisation des smartphones ne cesse de croître, proposer une application mobile
        devient indispensable pour élargir son audience. Cette application permet à vos utilisateurs
        d’avoir votre outil en permanence à portée de main et de l’utiliser même hors connexion.
      </p>

      <p>La mise à disposition d’une application mobile pour vos clients vous permet de:</p>
      <ul>
        <li>
          <strong>Gagner en accessibilité:</strong> vos utilisateurs peuvent vous contacter,
          réserver ou activer toute autre fonctionnalité en un simple geste,
        </li>
        <li>
          <strong>Augmenter votre chiffre d’affaire:</strong> faciliter la vie de vos clients c’est
          aussi favoriser les achats,
        </li>
        <li>
          <strong>Devancer vos concurrents:</strong> votre avant-garde technologique vous permet de
          gagner et de fidéliser de nouveaux clients,
        </li>
        <li>
          <strong>Accroître votre visibilité</strong> par votre présence sur le Play Store et l’App
          Store.
        </li>
      </ul>
      <p>
        La <strong>conception de votre application mobile</strong> se déroule de la manière
        suivante:
      </p>
      <ul>
        <li>
          Nous prenons soin de comprendre <strong>vos besoins</strong> pour vous proposer la
          meilleure <strong>solution technique</strong>,
        </li>
        <li>
          Nous développons une <strong>application mobile sur mesure</strong> à l’image de votre
          entreprise, suivant votre charte graphique,
        </li>
        <li>
          Votre application mobile est compatible <strong>iOS, Android ou hybride</strong>,
        </li>
        <li>
          Nous y intégrons les <strong>textes</strong> que vous nous fournissez ou nous créons du
          contenu optimisé,
        </li>
        <li>
          Nous incluons toutes les <strong>fonctionnalités</strong> utiles à vos clients,
        </li>
        <li>
          Nous mettons votre application à disposition de vos utilisateurs sur{' '}
          <strong>App Store et Google Play</strong>,
        </li>
        <li>
          Nous vous tenons régulièrement informé des <strong>performances</strong> de votre
          application.
        </li>
      </ul>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Envoyez-nous votre projet d'application mobile."
        subtitle="Nous sommes à votre écoute !"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar1} />
          <SimilarCard text="Graphic Design" url="/services/graphic-design/" img={similar2} />
          <SimilarCard
            text="Mise à jour du contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar3}
          />
          <SimilarCard text="Conseils" url="/services/conseils/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
